import { Injectable } from '@angular/core';
import { IPatient } from '../models/patient';
import { Subject, map } from 'rxjs';
import { IGeneral } from '../models/general';
import { environment } from 'src/environments/environment';
import { HttpService } from './http.service';
import { HttpParams } from '@angular/common/http';
import { INotification } from '../models/notification';

@Injectable({
  providedIn: 'root',
})
export class PatientService {
  constructor(private http: HttpService) {}

  sectorSubject = new Subject<void>();

  selectedSector = 0;

  watchSector() {
    return this.sectorSubject.asObservable();
  }

  unwatchSector() {
    this.sectorSubject.unsubscribe();
  }

  changeSector(id: number): void {
    this.selectedSector = id;
    this.sectorSubject.next();
  }

  getInitialsCharacter(inputString: string) {
    const words = inputString.split(' ');
    let initials = '';
    for (let i = 0; i < words.length; i++) {
      initials += words[i][0].toUpperCase();
      if (i !== words.length - 1) {
        initials += '.';
      }
    }
    return initials;
  }

  getPatients() {
    return this.http.get<IPatient[]>(`${environment.base_url}/patients/`).pipe(
      map((data) => {
        const patients = data.map((patient) => {
          const bandage = Number(Math.random() < 0.5);
          const variation = Number(Math.random() < 0.5);
          const reload = Number(Math.random() < 0.5);

          return {
            ...patient,
            progress: '100',
            lesion: (Math.random() * 10).toFixed(0).toString(),
            risk: 'Alto Risco',
            bandage,
            variation,
            reload,

            nameAbbreviation: this.getInitialsCharacter(patient.name),
          };
        });

        const patientsRisk = patients.sort((a, b) => {
          return a.braden - b.braden;
        });

        return patientsRisk;
      })
    );
  }

  getPatientNotification(patient: IPatient) {
    const query = new HttpParams().set('id', patient.id);

    return this.http.get<INotification[]>(
      `${environment.base_url}/list-notifications-patient/`,
      query
    );
  }

  getPatientSectorsAndBeds() {
    return this.http.get<IGeneral>(`${environment.base_url}/getConstants/`);
  }
}
