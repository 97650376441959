import {
  Component,
  ElementRef,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  offcanvasTopAnimation,
  slideInAnimation,
} from 'src/app/animations/route-animation';
import { StorageService } from './../../services/storage.service';
import { MatSidenav } from '@angular/material/sidenav';
import { PatientService } from 'src/app/services/patient.service';
import { ISector } from 'src/app/models/sector';
import { IBed } from 'src/app/models/patient';
import { ToggleFullScreenService } from 'src/app/services/toggle-full-screen.service';
import { FormBuilder } from '@angular/forms';
import { debounceTime, distinctUntilChanged, startWith } from 'rxjs';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
  animations: [slideInAnimation, offcanvasTopAnimation],
})
export class NavbarComponent implements OnInit, OnDestroy {
  @Input() colapse: 'vertical' | 'horizontal' = 'vertical';
  @ViewChild('navbar') navbar: ElementRef<HTMLElement> | undefined;
  @ViewChild('drawer') drawer: MatSidenav = {} as MatSidenav;

  constructor(
    private storage: StorageService,
    private patientService: PatientService,
    private toggleFullScreenService: ToggleFullScreenService,
    private fb: FormBuilder
  ) {}

  loading = false;

  isFullScreen = false;

  isOpen = false;
  sectorList: ISector[] = [];
  bedList: IBed[] = [];

  filter_form = this.fb.group({
    sector: [''],
  });

  ngOnInit(): void {
    this.getSectorsAndBeds();

    // Garantir que o valor inicial seja definido corretamente
    this.filter_form.patchValue({
      sector: '', // Garante que a opção "Todos" esteja selecionada
    });
  }

  ngOnDestroy(): void {
    this.storage.unwatchUser();
  }

  filterSubmitHandler(sector?: any) {
    console.log(sector);

    setTimeout(() => {
      this.patientService.changeSector(sector?.id || 0);
    }, 100);
  }

  async handleToggleNavbar() {
    this.isFullScreen = await this.toggleFullScreenService.toggleFullScreen(
      this.isFullScreen
    );
  }

  getSectorsAndBeds() {
    this.patientService.getPatientSectorsAndBeds().subscribe({
      next: (data) => {
        this.sectorList = data.sectors;
      },
    });
  }

  get serviceFormValue() {
    const value = this.filter_form.get('sector')?.value;
    return typeof value === 'string' ? value : '';
  }

  displayFn(deparment: { name: string }): string {
    return deparment && deparment.name ? deparment.name : 'Todos';
  }

  drawerToggle() {
    this.drawer.toggle();
    setTimeout(() => {
      dispatchEvent(new Event('resize'));
    }, 300);
  }

  logout() {
    this.storage.unwatchUser();
    this.storage.logout();
  }

  @HostListener('document:fullscreenchange', ['$event'])
  onFullScreenChange(event: Event): void {
    this.isFullScreen = !!document.fullscreenElement;
  }
}
