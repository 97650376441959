<div class="pagination">
  <span class="page f-12"> {{ current }} de {{ total }} </span>

  <div
    (click)="back && handlePageChange(current - 1)"
    class="back arrow f-12"
    [ngClass]="{ active: back }"
  >
    <i icon="angle_left"></i>
  </div>

  <div
    (click)="next && handlePageChange(current + 1)"
    class="next arrow f-12"
    [ngClass]="{ active: next }"
  >
    <i icon="angle_right"></i>
  </div>
</div>
