import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NavbarComponent } from './components/navbar/navbar.component';
import { AuthGuard } from './guards/auth.guard';

const SPR = false;

const routes: Routes = [
  {
    path: 'login',
    loadChildren: () =>
      import('./pages/login/login.module').then((m) => m.LoginModule),
  },
  {
    path: '',
    component: NavbarComponent,
    canActivate: [AuthGuard], // * Caso o projeto tenha rotas sem auth além de login, remover essa linha.
    children: [
      // {
      //   path: 'tables',
      //   loadChildren: () =>
      //     import('./pages/tables/tables.module').then((m) => m.TablesModule),
      // },
      // {
      //   path: 'indicators',
      //   loadChildren: () =>
      //     import('./pages/indicators/indicators.module').then(
      //       (m) => m.IndicatorsModule
      //     ),
      // },
      // {
      //   path: 'statistics',
      //   loadChildren: () =>
      //     import('./pages/statistics/statistics.module').then(
      //       (m) => m.StatisticsModule
      //     ),
      // },
      {
        path: 'patient-tv',
        loadChildren: () =>
          import('./pages/patient-tv/patient-tv.module').then(
            (m) => m.PatientTvModule
          ),
      },
      {
        path: '**',
        redirectTo: 'patient-tv',
      },
    ],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: SPR ? 'enabled' : 'disabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
